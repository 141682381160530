export default{
    props:{
        question: Object,
    }, 
    data: function(){
        return{autoSaveInterval: null}
    },
    computed:{
        studentAnswer:{
            set(value){
                if(this.isPreviewMode)
                    return
                if(this.isAssessmentSubmitted){
                    this.$store.commit('addSnackBarNotification',{
                        message: this.$t('submittedAssessmentAlert')
                    })
                    return
                }
                // if(this.checkAssessmentType === 'GP'){
                //     this.$store.commit('updateStudentAnswer', {
                //         question: this.question,
                //         answer:value
                //     })
                // }
                this.$store.commit('updateStudentAnswer', {
                    question: this.question,
                    answer:value
                })
                // this.$store.commit('sendStatusToFooter',{questionId:this.question.id})  
            },
            get(){
                return this.question.studentAnswer
            }
        },
        assessment(){
            return this.$store.state.assessment
        }, 
        isPreviewMode(){
            return this.$store.state.isPreviewModeActive
        }, 
        isAssessmentSubmitted(){
            // return this.assessment.assessmentCompleted
            // if(this.question.groupHeaderType === 1 || this.question.isSubmitted){
            //     return false
            // }
            // else if(this.question.isSubmitted && this.question.groupHeaderType === 2){
            //     return true
            // }
            // else{
            //     return false
            // }
            return this.question.isSubmitted
        }, 
        checkAssessmentType(){
            // return this.question.groupHeaderType === 1 ? 'GP': this.question.groupHeaderType === 2 ? 'IP' : 'NO'
        }
    },
    methods: {
        ToggleAutoSaveInterval(){
            if(this.assessmentSubmitted){
                this.$store.commit('addSnackBarNotification',{
                    message: this.$t('submittedAssessmentAlert')
                })
                return
            }
            clearInterval(this.autoSaveInterval)
            this.autoSaveInterval =setInterval(function () {
                this.updateStudentAnswer()
            }.bind(this), 5000); 
        }, 
        clearAutoSaveInterval(){
            this.updateStudentAnswer()
            clearInterval(this.autoSaveInterval)
        }
    },
    beforeDestroy(){
        if(this.isPreviewMode)
            return
        clearInterval(this.autoSaveInterval)
        if(this.question && this.assessment)
            this.updateStudentAnswer()
    }
}