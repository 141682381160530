var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.question
    ? _c("div", { staticClass: "question col-6 pl-4" }, [
        _c("div", { staticClass: "row d-baseline pl-3 mt-3" }, [
          _c(
            "div",
            [
              _vm.question.audio
                ? _c("AudioToggle", { attrs: { audio: _vm.question.audio } })
                : _vm._e()
            ],
            1
          ),
          _c("div", { staticClass: "d-inline__flex" }, [
            _c("span", {
              staticStyle: { "max-width": "100%", "margin-left": "20px" },
              attrs: { id: "question " },
              domProps: { innerHTML: _vm._s(_vm.question.question) }
            })
          ])
        ]),
        _c(
          "div",
          { staticClass: "choices pl-5" },
          _vm._l(_vm.question.choices, function(choice, index) {
            return _c(
              "label",
              {
                key: index,
                staticClass: "container-wrapper",
                class: choice.correctChoiceClass
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      width: "100%"
                    }
                  },
                  [
                    _c("div", [
                      _c("strong", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v(_vm._s(_vm.getCharacter(index) + "."))
                      ]),
                      _vm._v(" " + _vm._s(choice.text) + " \n            "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.studentAnswer,
                            expression: "studentAnswer"
                          }
                        ],
                        attrs: {
                          name: _vm.question.id,
                          type: "radio",
                          disabled: _vm.isAssessmentSubmitted
                        },
                        domProps: {
                          value: choice.id,
                          checked: _vm._q(_vm.studentAnswer, choice.id)
                        },
                        on: {
                          change: function($event) {
                            _vm.studentAnswer = choice.id
                          }
                        }
                      }),
                      _c("span", {
                        staticClass: "checkmark",
                        class: _vm.checkMarkStatus
                      })
                    ]),
                    _c("div", { staticClass: "audio-status-wrapper" }, [
                      _c(
                        "div",
                        {
                          staticClass: "status-active",
                          class: choice.correctChoiceClass
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: {
                              src: require("../../assets/BackOfficeAssets/tick.svg"),
                              alt: "",
                              width: "24"
                            }
                          }),
                          _c("div", [_vm._v("Correct")])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "status-inactive",
                          class: choice.correctChoiceClass
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: {
                              src: require("../../assets/BackOfficeAssets/cross.svg"),
                              alt: "",
                              width: "24"
                            }
                          }),
                          _c("div", [_vm._v("Incorrect")])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "status-active audio-response",
                          class: choice.correctChoiceClass
                        },
                        [
                          choice.responseAudio
                            ? _c("AudioToggle", {
                                attrs: { audio: choice.responseAudio }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "status-inactive audio-response",
                          class: choice.correctChoiceClass
                        },
                        [
                          choice.responseAudio
                            ? _c("AudioToggle", {
                                attrs: { audio: choice.responseAudio }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]
                ),
                !choice.responseText == ""
                  ? _c(
                      "div",
                      {
                        staticClass: "response-text status-active",
                        class: choice.correctChoiceClass
                      },
                      [_c("p", [_vm._v(_vm._s(choice.responseText))])]
                    )
                  : _vm._e(),
                !choice.responseText == ""
                  ? _c(
                      "div",
                      {
                        staticClass: "response-text status-inactive",
                        class: choice.correctChoiceClass
                      },
                      [_c("p", [_vm._v(_vm._s(choice.responseText))])]
                    )
                  : _vm._e()
              ]
            )
          })
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }