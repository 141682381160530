<template>
  <div
    class="questions-container column mb-5"
    :style="{ order: content.order }"
    :id="'cmp' + content.id"
  >
    <div class="instruction-outer" v-if="content.instruction">
      <AudioToggle
        class="pl-1 primary-audio"
        v-if="content.instructionAudio"
        :audio="content.instructionAudio"
      />
      <div class="ml-4 writen-instruction-text" v-html="content.instruction"></div>
    </div>
    <div class="row pl-4 written-primary-text">
      <AudioToggle
        class="ml-4 mt-3"
        style="padding-left: 2px"
        v-if="content.primarySourceAudio"
        :audio="content.primarySourceAudio"
      />
      <span
        id="question"
        class="ml-4 mt-3 question"
        v-html="content.primaryTextSource"
      >
      </span>
    </div>
    <template>
      <written-question :question="content" />
      <AnswerFeedBack v-if="content.feedback" :question="content" />
    </template>
    <div class="outer-mcq-submit">
      <button
        class="btn round primary back"
        @click="submitAssessmetGroup"
        :disabled="content.isSubmitted || isPreviewMode"
        :class="content.isSubmitted ? 'secondary cursor-none' : 'primary'"
      >
        {{ $t("submit") }}
      </button>
    </div>
    <modal
      v-if="showModal"
      :click-handler="completeAssessment"
      text-button="OK"
      @close="showModal = false"
    >
      <div class="message">
        <div>{{ submitMessage }}</div>
      </div>
    </modal>
  </div>
</template>

<script>
import WrittenQuestion from "./WrittenQuestion.vue";
import AnswerFeedBack from "@/components/assessment/AnswerFeedBack.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import Modal from "@/common/Modal.vue";
export default {
  name: "WrittenSection",
  props: ["content"],
  components: {
    WrittenQuestion,
    AnswerFeedBack,
    AudioToggle,
    Modal,
  },
  data() {
    return {
      showModal: false,
      submitMessage: "",
    };
  },
  computed: {
    isPreviewMode() {
      return this.$store.state.isPreviewModeActive;
    },
    // questions(){
    //     return this.$store.state.assessment.questions.filter(elem => elem.type === "written");
    // }
  },
  methods: {
    submitAssessmetGroup() {
      this.showModal = true;
      this.submitMessage = this.$t("submitAssessmentMessage");
    },
    completeAssessment() {
      this.showModal = false;
      // this.isLoadingInformation = true
      this.$store.commit("loadCurrentQuestion", this.content);
      this.$store.dispatch("submitWrittenQuestion", {
        lessonId: this.$route.params.lessonId,
        questionId: this.content.id,
      });
      this.$store.commit("submitIndividualQuestion");
    },
  },
};
</script>

<style lang="less" scoped>
.instruction-outer {
  padding-left: 44px !important;
  padding: 20px;
  background: white;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
}
.outer-mcq-submit {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.questions-container {
  background-color: #e0e0e0;
  // border-radius: 16px;

  > div {
    margin-top: 0px;
  }

  .written-question:first-child {
    margin-top: 0;
  }
}
.question {
  max-width: 94%;
}
.written-primary-text {
  background-color: #f0f0f0 !important;
  width: 100%;
  margin-left: 0.5px;
  margin-top: 0 !important;
  display: flex;
  flex-wrap: nowrap;
}
// .writen-instruction-text{
//   max-width: 60%;
// }
</style>
