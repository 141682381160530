var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "answer-feedback flex-container" }, [
    _vm.question
      ? _c("i", { staticClass: "circle", class: _vm.circleStatus }, [
          _c("div", { class: _vm.IconStatus })
        ])
      : _vm._e(),
    _c("pre", [
      _vm._v("    "),
      _c("strong", [_vm._v(_vm._s(_vm.$t("feedback")))]),
      _vm._v("\n    " + _vm._s(_vm.question.feedback))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }