var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "essay-answer row" }, [
    _c("section", { staticClass: "column pl-3" }, [
      _c(
        "div",
        { staticClass: "row essay-question essay-question-audio pl-4" },
        [
          _c("AudioToggle", {
            staticClass: "essay-primary_audio",
            attrs: { audio: _vm.question.audio || {} }
          }),
          _c("p", {
            staticClass: "essay-questiom-text",
            staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
            domProps: { innerHTML: _vm._s(_vm.question.question) }
          })
        ],
        1
      )
    ]),
    _c("section", { staticClass: "pb-5 mr-4 pl-5" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.studentAnswer,
            expression: "studentAnswer"
          }
        ],
        staticClass: "custom-scrollbar essay-ans_student",
        attrs: {
          name: "studentAnswer",
          placeholder: "Type your answer here...",
          readonly: _vm.isAssessmentSubmitted
        },
        domProps: { value: _vm.studentAnswer },
        on: {
          focus: function($event) {
            _vm.ToggleAutoSaveInterval()
          },
          blur: function($event) {
            _vm.clearAutoSaveInterval()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.studentAnswer = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }