<template>
  <div
    class="questions-container row mcq__row mb-5"
    :style="{ order: content.order }"
    :id="'cmp' + content.id"
  >
    <div
      :class="
        content.imageName.length === 0
          ? 'instruction-outer-center'
          : 'instruction-outer'
      "
      v-if="content.instruction"
    >
      <AudioToggle
        class="pl-1 primary-audio"
        v-if="content.instructionAudio"
        :audio="content.instructionAudio"
      />

      <div class="ml-4 instruction-text" v-html="content.instruction"></div>
    </div>
    <div class="row primary_text_audio-image">
      <div
        class="primary_text_audio"
        :class="[
          content.primaryTextSource.length === 0 ? 'no-primary_source' : '',
        ]"
      >
        <div class="primary_source_text_innerdiv mt-3">
          <AudioToggle
            class="pl-1 primary-audio"
            v-if="content.primarySourceAudio"
            :audio="content.primarySourceAudio"
          />
          <span
            id="question"
            class="pr-2 primary_source_text"
            v-html="content.primaryTextSource"
          >
          </span>
        </div>
      </div>
      <div
        class="images-section column"
        :class="content.primaryTextSource.length === 0 ? 'col-12' : ''"
      >
        <img ref="mcqimage" :src="getImage(content.imageName)" />
      </div>
    </div>

    <div class="multiple-choice_flex">
      <div class="column">
        <multiple-choice-question :question="content" />
      </div>
    </div>
    <div class="outer-mcq-submit">
      <button
        class="btn round primary back"
        @click="submitAssessmetGroup"
        :disabled="
          content.isSubmitted || isPreviewMode || studentAnswerId === null
            ? true
            : false
        "
        :class="content.isSubmitted ? 'secondary cursor-none' : 'primary'"
      >
        {{ $t("submit") }}
      </button>
    </div>
    <modal
      v-if="showModal"
      :click-handler="completeAssessment"
      text-button="OK"
      @close="showModal = false"
    >
      <div class="message">
        <div>{{ submitMessage }}</div>
      </div>
    </modal>
  </div>
</template>
<script>
import MultipleChoiceQuestion from "@/components/assessment/MultipleChoiceQuestion.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import Modal from "@/common/Modal.vue";
export default {
  name: "MultipleChoiceSection",
  props: ["content"],
  components: {
    MultipleChoiceQuestion,
    AudioToggle,
    Modal,
  },
  data() {
    return {
      showModal: false,
      submitMessage: "",
      primarySourceImage: null,
      studentAnswerId: null,
    };
  },
  methods: {
    getImage(image) {
      if (image === 0 || !image) return;
      return `${process.env.VUE_APP_CDN}images/${image}`;
    },
    submitAssessmetGroup() {
      this.showModal = true;
      this.submitMessage = this.$t("submitAssessmentMessage");
    },
    completeAssessment() {
      this.showModal = false;
      this.$store.commit("loadCurrentQuestion", this.content);
      this.$store.dispatch("submitMultipleChoice", {
        lessonId: this.$route.params.lessonId,
        questionId: this.content.id,
        studentAnswerChoiceId: this.studentAnswerId,
      });
      this.$store.commit("submitIndividualQuestion");

      // this.$root.$emit("testQuestionSubmit", this.content);
    },
  },
  computed: {
    isPreviewMode() {
      return this.$store.state.isPreviewModeActive;
    },
    // questions(){
    //     return this.$store.state.assessment.questions
    //             .filter(e => e.type === 'multiple-choice')
    // },
    // imagesFromQuestions(){
    //     return this.questions
    //         .filter(e => e.imageName)
    //         .map(e => {
    //             return e.imageName
    //     })
    // }
  },
  mounted() {
    this.$root.$on("studentanswer", (studentAnswerid) => {
      this.studentAnswerId = studentAnswerid;
    });
  },
  updated() {
    setTimeout(() => {
      // this.getPrimarySourceImageWidth();
    }, 1000);
  },
};
</script>
<style lang="less" scoped>
.instruction-outer {
  padding: 20px;
  padding-left: 0px !important;
  background: white;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
}
.outer-mcq-submit {
  padding: 10px;
  text-align: center;
}
.questions-container {
  .images-section {
    padding-inline: 0;

    img {
      height: auto;
      width: 100%;
      margin-top: 16px;

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
.multiple-choice_flex {
  display: flex;
  margin-left: 20.6%;
}
.mcq__row {
  display: block;
  margin-left: 1px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: rgb(245, 245, 245, 0.1);
}
.content-component .ck.ck-editor {
  margin-top: 0px !important;
}
.primary_text_audio {
  padding-right: 8px;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  background: #e0e0e0;
}
.primary_text_audio-image {
  width: 100%;
  margin-left: 0.5px;
  display: flex;
  flex-wrap: nowrap;
  @media (min-width: 0) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
}
.primary-audio {
  max-width: none;
  width: unset;
  display: flex;
  margin-left: 20px;
}
.primary-text {
  width: -webkit-fill-available;
  // text-align: center;
}
.primary-text > p {
  widows: -webkit-fill-available;
  overflow: hidden !important;
  // text-align: center;
}

.no-primary_source {
  width: 0;
  padding-right: 0px;
}
.primary_source_text_innerdiv {
  display: flex;
  justify-content: center;
  align-content: center;
}
.primary_source_text {
  max-width: 656px;
  margin-left: 20px;
}
.primary_source_text > p {
  margin-block: 0px;
}
// .instruction-text{
//   max-width: 60%;
// }
.instruction-outer-center {
  padding: 20px;
  padding-left: 0px !important;
  background: white;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
  .instruction-text {
    max-width: 60%;
  }
  .primary-audio{
    margin-left: 9.5%;
  }
}
</style>

