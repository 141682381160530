var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "questions-container row mcq__row mb-5",
      style: { order: _vm.content.order },
      attrs: { id: "cmp" + _vm.content.id }
    },
    [
      _vm.content.instruction
        ? _c(
            "div",
            {
              class:
                _vm.content.imageName.length === 0
                  ? "instruction-outer-center"
                  : "instruction-outer"
            },
            [
              _vm.content.instructionAudio
                ? _c("AudioToggle", {
                    staticClass: "pl-1 primary-audio",
                    attrs: { audio: _vm.content.instructionAudio }
                  })
                : _vm._e(),
              _c("div", {
                staticClass: "ml-4 instruction-text",
                domProps: { innerHTML: _vm._s(_vm.content.instruction) }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "row primary_text_audio-image" }, [
        _c(
          "div",
          {
            staticClass: "primary_text_audio",
            class: [
              _vm.content.primaryTextSource.length === 0
                ? "no-primary_source"
                : ""
            ]
          },
          [
            _c(
              "div",
              { staticClass: "primary_source_text_innerdiv mt-3" },
              [
                _vm.content.primarySourceAudio
                  ? _c("AudioToggle", {
                      staticClass: "pl-1 primary-audio",
                      attrs: { audio: _vm.content.primarySourceAudio }
                    })
                  : _vm._e(),
                _c("span", {
                  staticClass: "pr-2 primary_source_text",
                  attrs: { id: "question" },
                  domProps: { innerHTML: _vm._s(_vm.content.primaryTextSource) }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "images-section column",
            class: _vm.content.primaryTextSource.length === 0 ? "col-12" : ""
          },
          [
            _c("img", {
              ref: "mcqimage",
              attrs: { src: _vm.getImage(_vm.content.imageName) }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "multiple-choice_flex" }, [
        _c(
          "div",
          { staticClass: "column" },
          [
            _c("multiple-choice-question", { attrs: { question: _vm.content } })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "outer-mcq-submit" }, [
        _c(
          "button",
          {
            staticClass: "btn round primary back",
            class: _vm.content.isSubmitted
              ? "secondary cursor-none"
              : "primary",
            attrs: {
              disabled:
                _vm.content.isSubmitted ||
                _vm.isPreviewMode ||
                _vm.studentAnswerId === null
                  ? true
                  : false
            },
            on: { click: _vm.submitAssessmetGroup }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("submit")) + "\n    ")]
        )
      ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.completeAssessment,
                "text-button": "OK"
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "message" }, [
                _c("div", [_vm._v(_vm._s(_vm.submitMessage))])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }