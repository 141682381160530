<template>
  <div class="essay-answer row">
    <section class="column pl-3">
      <div class="row essay-question essay-question-audio pl-4">
        <AudioToggle class="essay-primary_audio" :audio="question.audio || {}" />
        <!-- <span>{{question.order}}.</span> -->
        <p class="essay-questiom-text" style="margin-top: 8px; margin-bottom: 8px" v-html="question.question"></p>
      </div>
    </section>
    <section class="pb-5 mr-4 pl-5">
      <textarea
        name="studentAnswer"
        placeholder="Type your answer here..."
        v-model="studentAnswer"
        class="custom-scrollbar essay-ans_student"
        @focus="ToggleAutoSaveInterval()"
        @blur="clearAutoSaveInterval()"
        :readonly="isAssessmentSubmitted"
      ></textarea>
    </section>
  </div>
</template>
<script>
import AudioToggle from "@/common/AudioToggle.vue";
import QuestionMixin from "./QuestionMixin.js";
export default {
  name: "EssayTextArea",
  mixins: [QuestionMixin],
  components: {
    AudioToggle,
  },
  methods: {
    updateStudentAnswer() {
      if (!this.studentAnswer) return;
      this.$store.dispatch("updateEssayStudentAnswer", {
        assessmentId: this.assessment.id,
        questionId: this.question.id,
        essayText: this.studentAnswer,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.audio-toggle {
  margin-top: 24px;
  margin-left: 24px;
}
.essay-answer.row {
  margin-top: 0;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.38);

  section:nth-child(2) {
    margin-left: 72px;
    // margin-right: 24px;
    width: 100%;
    height: 100%;
    span {
      display: block;
      // min-height: 81px;
      min-width: 24px;
      color: #0a1554;
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 28px;
      white-space: normal;
      width: auto;
      margin-left: -32px;
      &:nth-child(2) {
        margin-left: 8px;
        margin-top: -1em;
      }
    }

    textarea,
    p {
      // all: unset;
      border: none;
      min-height: 144px;
      max-height: none;
      height: auto;

      width: calc(100% - 48px);
      // width: 76vw;
      margin-right: 24px;
      background-color: #fff;
      padding: 24px;
      letter-spacing: 0.5px;
      line-height: 18px;

      color: #0a1554;
      font-size: 16px;

      overflow-wrap: break-word;
      // white-space:nowrap;
      white-space: pre-wrap;
    }
  }
}
.essay-ans_student {
  border: 1px solid !important;
  border-radius: 8px !important;
}
.essay-question-audio{
    margin-right: 6px;
    display: flex;
    flex-wrap: nowrap;
}
.essay-primary_audio{
    margin-top: 8px !important;
    margin-right: 28px;
}
.essay-questiom-text{
    max-width: 90%;
}
</style>


