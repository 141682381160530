<template>
  <div
    class="essay-container mb-5"
    :style="{ order: content.order }"
    :id="'cmp' + content.id"
  >
    <div class="instruction-outer" v-if="content.instruction">
      <AudioToggle
        class="pl-1 primary-audio"
        v-if="content.instructionAudio"
        :audio="content.instructionAudio"
      />
      <div class="ml-4 instruction-text" v-html="content.instruction"></div>
    </div>
    <div class="row essay-primary-text">
      <AudioToggle
        class="ml-4 mt-3"
        v-if="content.primarySourceAudio"
        :audio="content.primarySourceAudio"
      />
      <span
        id="question"
        class="ml-4 mt-3 question"
        v-html="content.primaryTextSource"
      >
      </span>
    </div>
    <EssayTextArea :question="content" />
    <AnswerFeedBack v-if="feedback" :question="content" />
    <div class="outer-mcq-submit">
      <button
        class="btn round primary back"
        @click="submitAssessmetGroup"
        :disabled="content.isSubmitted || isPreviewMode"
        :class="content.isSubmitted ? 'secondary cursor-none' : 'primary'"
      >
        {{ $t("submit") }}
      </button>
    </div>
    <modal
      v-if="showModal"
      :click-handler="completeAssessment"
      text-button="OK"
      @close="showModal = false"
    >
      <div class="message">
        <div>{{ submitMessage }}</div>
      </div>
    </modal>
  </div>
</template>
<script>
import EssayTextArea from "@/components/assessment/EssayTextArea.vue";
import AnswerFeedBack from "@/components/assessment/AnswerFeedBack.vue";
import AudioToggle from "@/common/AudioToggle.vue";
import Modal from "@/common/Modal.vue";
export default {
  name: "EssaySection",
  props: ["content"],
  components: {
    EssayTextArea,
    AnswerFeedBack,
    AudioToggle,
    Modal,
  },
  data() {
    return {
      showModal: false,
      submitMessage: "",
    };
  },
  computed: {
    feedback() {
      return this.content.feedback;
    },
    isPreviewMode() {
      return this.$store.state.isPreviewModeActive;
    },
    // essay(){
    //     return this.assessment.questions.find(e => e.type === 'essay')
    // },
    // assessment(){
    //     return this.$store.state.assessment
    // },
  },
  methods: {
    submitAssessmetGroup() {
      this.showModal = true;
      this.submitMessage = this.$t("submitAssessmentMessage");
    },
    completeAssessment() {
      this.showModal = false;
      // this.isLoadingInformation = true
      this.$store.commit("loadCurrentQuestion", this.content);
      this.$store.dispatch("submitEssayQuestion", {
        lessonId: this.$route.params.lessonId,
        questionId: this.content.id,
      });
      this.$store.commit("submitIndividualQuestion");
    },
  },
};
</script>
<style>
.instruction-outer {
  padding-left: 30px !important;
  padding: 20px;
  background: white;
  border: 1px solid #ededed;
  display: flex;
  justify-content: center;
}
.outer-mcq-submit {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.question {
  max-width: 90%;
}
.essay-container {
  background: #e0e0e0;
  /* border-radius: 16px; */
}
.essay-primary-text {
  width: 100%;
  padding-left: 10px;
  margin-left: 0.5px;
  background-color: #f0f0f0 !important;
  display: flex;
  flex-wrap: nowrap;
}
/* .instruction-text{
  max-width: 60%;
} */
</style>
