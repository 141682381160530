var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "essay-container mb-5",
      style: { order: _vm.content.order },
      attrs: { id: "cmp" + _vm.content.id }
    },
    [
      _vm.content.instruction
        ? _c(
            "div",
            { staticClass: "instruction-outer" },
            [
              _vm.content.instructionAudio
                ? _c("AudioToggle", {
                    staticClass: "pl-1 primary-audio",
                    attrs: { audio: _vm.content.instructionAudio }
                  })
                : _vm._e(),
              _c("div", {
                staticClass: "ml-4 instruction-text",
                domProps: { innerHTML: _vm._s(_vm.content.instruction) }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row essay-primary-text" },
        [
          _vm.content.primarySourceAudio
            ? _c("AudioToggle", {
                staticClass: "ml-4 mt-3",
                attrs: { audio: _vm.content.primarySourceAudio }
              })
            : _vm._e(),
          _c("span", {
            staticClass: "ml-4 mt-3 question",
            attrs: { id: "question" },
            domProps: { innerHTML: _vm._s(_vm.content.primaryTextSource) }
          })
        ],
        1
      ),
      _c("EssayTextArea", { attrs: { question: _vm.content } }),
      _vm.feedback
        ? _c("AnswerFeedBack", { attrs: { question: _vm.content } })
        : _vm._e(),
      _c("div", { staticClass: "outer-mcq-submit" }, [
        _c(
          "button",
          {
            staticClass: "btn round primary back",
            class: _vm.content.isSubmitted
              ? "secondary cursor-none"
              : "primary",
            attrs: { disabled: _vm.content.isSubmitted || _vm.isPreviewMode },
            on: { click: _vm.submitAssessmetGroup }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("submit")) + "\n    ")]
        )
      ]),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.completeAssessment,
                "text-button": "OK"
              },
              on: {
                close: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c("div", { staticClass: "message" }, [
                _c("div", [_vm._v(_vm._s(_vm.submitMessage))])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }