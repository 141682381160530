<template>
    <div class="question col-6 pl-4" v-if="question">
        <div class="row d-baseline pl-3 mt-3">
            <div>
            <AudioToggle v-if="question.audio" :audio="question.audio"/>
            <!-- <span>{{question.order}}.</span> -->
            </div>
            <div class="d-inline__flex">
            <span id="question " style="max-width:100%; margin-left:20px" v-html="question.question"> </span>
            </div>
        </div>
        <div class="choices pl-5">
            <label class="container-wrapper"  v-for="(choice, index) in question.choices" :class="choice.correctChoiceClass"
             :key="index">
             <div class="" style="display: flex; justify-content: space-between; width: 100%;">
                 <div>
             <strong style="margin-right: 5px;">{{getCharacter(index)+ '.' }}</strong> {{choice.text}} 
                <input :name="question.id" type="radio" :disabled="isAssessmentSubmitted"
                     :value="choice.id" v-model="studentAnswer"/>
                <span class="checkmark" :class="checkMarkStatus"></span>
                </div>
                    <div class="audio-status-wrapper">
                        <div :class="choice.correctChoiceClass" class="status-active">
                            <img src="../../assets/BackOfficeAssets/tick.svg" alt="" style="margin-right: 5px;" width="24">
                            <div>Correct</div>
                            
                        </div>
                        <div :class="choice.correctChoiceClass" class="status-inactive">
                            <img src="../../assets/BackOfficeAssets/cross.svg" alt="" style="margin-right: 5px;" width="24">
                            <div>Incorrect</div>
                        </div>
                        <div :class="choice.correctChoiceClass" class="status-active audio-response">
                            <AudioToggle v-if="choice.responseAudio" :audio="choice.responseAudio"/>
                        </div>
                        <div :class="choice.correctChoiceClass" class="status-inactive audio-response">
                            <AudioToggle v-if="choice.responseAudio" :audio="choice.responseAudio"/>
                        </div>
                    </div>
                </div>
                <div class="response-text status-active" :class="choice.correctChoiceClass" v-if="!choice.responseText == ''">
                        <p>{{choice.responseText}}</p>
                </div>
                <div class="response-text status-inactive" :class="choice.correctChoiceClass" v-if="!choice.responseText == ''">
                        <p>{{choice.responseText}}</p>
                </div>
            </label>  
        </div>
        <!-- <div class="message" v-if="checkMarkStatus === 'failed'">
            <h2 style="color: #ff5252">{{ $t("wrongAnswerText") }}</h2>
        </div>
        <div class="message" v-if="checkMarkStatus === 'correct'">
            <h2 style="color: #04AA6D">{{ $t("correctAnswerText") }}</h2>
        </div> -->
        
    </div>
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'

export default {
    name: 'MultipleChoiceQuestion', 
    components:{
        AudioToggle
    },
    props:{
        question: Object, 
    }, 
    computed:{
        studentAnswer:{
            set(answer){
                if(this.isPreviewMode)
                    return
                if(this.isAssessmentSubmitted){
                    this.$store.commit('addSnackBarNotification',{
                        message: this.$t('submittedAssessmentAlert')
                    })
                    return
                }
                    // if(this.checkAssessmentType === 'GP'){
                       
                    //     this.$store.commit('updateMultipleChoiceQuestionStudentAnswer',{
                    //         question: this.question, 
                    //         answer: answer
                    //     })
                    //     return
                    // }
                this.$store.dispatch('setMultipleChoiceQuestionStudentAnswer', {
                        questionId: this.question.id,
                        studentAnswerChoiceId: answer
                    })
                    .then(()=>{
                        this.$store.commit('updateMultipleChoiceQuestionStudentAnswer',{
                            question: this.question, 
                            answer: answer
                        })
                    })
                // this.$store.commit('sendStatusToFooter',{questionId:this.question.id})   
                this.$root.$emit('studentanswer', answer) 
            }, 
            get(){
                let correctAnwer =  this.question.choices.find(e => e.correct)
                return correctAnwer ? correctAnwer.id : null
            }
        }, 
        isPreviewMode(){
            return this.$store.state.isPreviewModeActive
        }, 
        // assessment(){
        //     // return this.$store.state.assessment
        //     return this.question.isSubmitted
        // }, 
        isAssessmentSubmitted(){
          
            // return this.assessment.assessmentCompleted
            return this.question.isSubmitted
        }, 
        checkMarkStatus(){
            //  if(this.checkAssessmentType == 'GP'){
            //      return this.studentAnswer != this.question.correctChoiceId
            //     ? 'failed' : 'correct'
            // }
            if(this.question.isCorrect === null){
                  return
            }
              else {
                   return this.studentAnswer != this.question.correctChoiceId
                ? 'failed' : 'correct'
                //    var selectedObj = this.question.choices.find(e => e.correct)
                //    console.log(selectedObj,"selected")
                //   this.question.choices.map((item)=>{
                //       if(selectedObj.id === item.id){
                //            item.status = 'correct'
                //       }
                //       else {
                //           item.status = 'failed'
                //       }
                //   })
                   
                    // item.status ='correct'
                   
                // })
                
              }
           
        //    return this.question.correctChoiceId ? 'correct' : this.studentAnswer != this.question.correctChoiceId ? 'failed': ''
              

        },
        // checkAssessmentType(){
        //     // return this.question.groupHeaderType == 1 ? 'GP': this.question.groupHeaderType == 2 ? 'IP' : 'NO'
        // }
       
    },
    methods:{
         getCharacter(number){
            let string = "abcd"
            return string.charAt(number)
        }
    },
    updated(){
        if(this.studentAnswer){
             this.$root.$emit('studentanswer', this.studentAnswer) 
        }
    },
    
}
</script>
<style lang="less" scoped>
.question .choices .container-wrapper.correctChoice{
  background: #DEEDDE;
  padding-top: 15px;
  padding-bottom: 10px;
}
.question .choices .container-wrapper.incorrectChoice{
    background: #F6DDD7;
    padding-top: 15px;
  padding-bottom: 15px;
}
.container-wrapper.correctChoice .checkmark, .container-wrapper.incorrectChoice .checkmark{
    top: 17px;
}
.status-active{
    display: none;
}
.status-active.correctChoice{
    display: flex;
    // background: whitesmoke;
    position: relative;
    // left: 100px;
    color: #57af57;
    margin-right: 20px;
}

.status-inactive{
    display: none;
}
.status-inactive.incorrectChoice{
    display: flex;
    // background: whitesmoke;
    position: relative;
    // left: 97px;
    color: #ef6363;
     margin-right: 20px;
}
.audio-status-wrapper{
    width: 100px;
    display: flex;
    justify-content: flex-end;
}
.question{
    height: auto;
    min-width: 592px;
    // margin-top: 16px;
    
    .row{
        align-content: center;
        img{
            align-self: center;
        }
        // .audio-toggle{
        //     // margin-right: 20px;
        // }
        span{
           min-width: 24px;
           min-height: 28px;
           color: #0A1554;
           font-family: 'Roboto', sans-serif;
           font-size: 20px;
           letter-spacing: 0.5px;
           line-height: 28px;
           white-space: normal;
       }
        #question{
            margin-top: -1em;
            margin-left: 8px;
        }
    }
    .choices{
        margin-top: 12px;
        .container{
            display: flex;  
            margin-top: 24px;
            padding-right: 0px;
        }
    }
    
}

.container-wrapper {
  display: block;
  justify-content: space-between;
  position: relative;
  padding-left: 35px;
//   padding-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 20px;

    min-height: 20px;
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 22px;
}

/* Hide the browser's default radio button */
.container-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  max-width: 200px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 12px;
  height: 14px;
  width: 14px;
  background-color: #D8D8D8;
  border-radius: 50%;
  margin-top: 3px;
}

.container-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

.container-wrapper input:checked ~ .checkmark {
  background-color: #0A1554;
}
.container-wrapper input:checked ~ .checkmark.failed {
  background-color: #ff5252;
}
.container-wrapper input:checked ~ .checkmark.correct {
  background-color: #B8E986;
}
    //  .checkmark.failed {
    //   background-color: #ff5252;
    // }
    // .checkmark.correct {
    //   background-color: #B8E986;
    // }
.d-baseline{
    display: flex;
    align-items: baseline;
}
.d-inline__flex{
    display: inline-flex !important;
    width: 88% !important;
}
</style>

