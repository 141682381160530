var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "written-question column" }, [
    _c(
      "div",
      { staticClass: "row written-question-text_audio pt-2" },
      [
        _vm.question.audio
          ? _c("AudioToggle", { attrs: { audio: _vm.question.audio } })
          : _vm._e(),
        _c("div", { staticClass: "row written-question-width ml-4 pr-3" }, [
          _c("span", { domProps: { innerHTML: _vm._s(_vm.question.question) } })
        ])
      ],
      1
    ),
    _c("div", { staticClass: "row pl-2 ml-5 mr-5" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.studentAnswer,
            expression: "studentAnswer"
          }
        ],
        staticClass: "custom-scrollbar written-ans",
        attrs: {
          readonly: _vm.isAssessmentSubmitted,
          placeholder: _vm.$t("typeInAnAnswer") + "..."
        },
        domProps: { value: _vm.studentAnswer },
        on: {
          focus: function($event) {
            _vm.ToggleAutoSaveInterval()
          },
          blur: function($event) {
            _vm.clearAutoSaveInterval()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.studentAnswer = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }