<template>
    <div class="answer-feedback flex-container">
        <i class="circle" :class="circleStatus" v-if="question"> 
            <div :class="IconStatus"></div>
        </i>
        <pre>
        <strong>{{$t('feedback')}}</strong>
        {{question.feedback}}</pre>
    </div>
</template>
<script>
export default {
    name:'AnswerFeedBack', 
    props:{
        question: Object
    },
    computed:{
        circleStatus(){
            return this.question.score >= 70 ? 'completed' : 'failed'
        },
        IconStatus(){
            return this.question.score >= 70 ? 'check' : 'cross'
        }
    }
}
</script>
<style lang="less" scoped>
.answer-feedback{    
    margin: 0 auto;
	// width: 944px;
    width: 100%;
    background-color: #EAEBFF;
    margin-top: 15px;

    pre, textarea{
        margin-left: 60px;
        margin-top: 14px;
        width: 655px;
        max-width: 655px;
        color: #0A1554;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 16px;
        overflow: hidden;
        overflow-y: auto;
    }

    .circle{
        margin-left: 32px;
        margin-top: 28px;
        margin-bottom: 28px;
    }
}

</style>


